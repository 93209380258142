import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var routesPC = [
    {
        path: '/get-start',
        component: function () { return import(/* webpackChunkName: "start" */ '@/views/pc/get-start.vue'); },
        meta: {
            description: 'Want to track your employees effectively? Try Marki! Free employee tracking app with unique watermarks and features to boost your management efficiency in field services.',
            title: 'Marki丨Free Time & Location Tracking APP with Watermarks'
        }
    },
    {
        path: '/policy',
        component: function () { return import(/* webpackChunkName: "start" */ '@/views/pc/policy.vue'); },
        meta: {
            description: 'Want to track your employees effectively? Try Marki! Free employee tracking app with unique watermarks and features to boost your management efficiency in field services.',
            title: 'Marki丨Free Time & Location Tracking APP with Watermarks',
            noIndex: true
        }
    },
    {
        path: '/industries/:type',
        component: function () { return import(/* webpackChunkName: "start" */ '@/views/pc/industries.vue'); },
        meta: {
            title: 'Industries',
            description: 'Suitable for field services that extensively require remote monitoring.'
        }
    },
    {
        path: '/about',
        component: function () { return import(/* webpackChunkName: "start" */ '@/views/pc/about.vue'); },
        meta: {
            title: 'About Us',
            description: 'A Singapore-based leading provider of integrated internet services.'
        }
    },
    {
        path: '/features',
        component: function () { return import(/* webpackChunkName: "start" */ '@/views/pc/features.vue'); },
        meta: {
            title: 'Features',
            description: 'Watermarks, attendance recording, GPS tracking, team moments and... you name it!'
        }
    },
    {
        path: '/',
        component: function () { return import(/* webpackChunkName: "home" */ '@/views/pc/Home.vue'); },
        meta: {
            description: 'Want to track your employees effectively? Try Marki! Free employee tracking app with unique watermarks and features to boost your management efficiency in field services.',
            title: 'Marki丨Free Time & Location Tracking APP with Watermarks'
        }
    },
    {
        path: '*',
        redirect: '/'
    }
];
var routesMobile = [
    {
        path: '/',
        component: function () { return import(/* webpackChunkName: "home" */ '@/views/mobile/Home.vue'); }
    }, {
        path: '/partner',
        component: function () { return import(/* webpackChunkName: "home" */ '@/views/mobile/partner.vue'); }
    },
    {
        path: '*',
        redirect: '/'
    }
];
export var routerPC = new VueRouter({
    mode: 'history',
    routes: routesPC
});
routerPC.beforeEach(function (to, from, next) {
    var _a;
    document.title = to.meta.title;
    (_a = document.querySelector('meta[name="description"]')) === null || _a === void 0 ? void 0 : _a.setAttribute('content', to.meta.description);
    if (to.meta.noIndex) {
        var metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex';
        document.getElementsByTagName('head')[0].appendChild(metaTag);
    }
    next();
});
export var routerMobile = new VueRouter({
    mode: 'history',
    routes: routesMobile
});

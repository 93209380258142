/**
 * API请求模块
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import API from 'yypkg/api';
import getUrlParam from 'yypkg/get-url-param';
export default function () {
    var _this = this;
    var isMockParam = !!(getUrlParam('mock') === '1');
    var isProd = process.env.NODE_ENV;
    var baseURL = isProd === 'production' ? 'https://api.markicam.com' : 'https://api.markicam.com';
    if (isMockParam) {
        baseURL = 'http://www.yapi-efox.duowan.com/mock/358';
    }
    var urls = {
        collectEmail: '/wms/email/collect'
    };
    var options = {
        baseURL: baseURL,
        RESTful: true,
        timeout: 5000
    };
    var api = new API(urls, options);
    var lang = window.navigator.language; // 常规浏览器语言和IE浏览器
    lang = lang.substr(0, 2); // 截取lang前2位字符
    var timezoneOffset = new Date().getTimezoneOffset();
    // 前置拦截器动态传 header 参数，因为登录前后 token 会变动
    api.$on('interceptor:beforeResolveOptions', function (beforeResolveOptions) {
        var headers = {
            'dw-language': lang,
            timezoneOffset: timezoneOffset,
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        var data = beforeResolveOptions.data, options = beforeResolveOptions.options;
        options = options || {};
        options.headers = headers;
        return { data: data, options: options };
    });
    api.$on('interceptor:beforeRequest', function (options) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    }); });
    api.$on('interceptor:afterCallbackResponse', function (options) {
    });
    api.$on('error', function (error) {
        var toast = error.message;
        if ((/timeout/i).test(error.message)) {
            toast = '接口请求超时，请重试';
        }
        console.error(error.message);
        console.error(error.config);
    });
    return api;
}

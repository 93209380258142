import '@/styles/main';
import Vue from 'vue';
import { routerPC, routerMobile } from '@/router';
import store from '@/store';
import Pc from '@/Pc.vue';
import Mobile from '@/Mobile.vue';
import api from '@/base/api';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import isMobile from 'yypkg/is-mobile';
!window.Promise && (window.Promise = Promise);
window.API = api();
Vue.component(VueQrcode.name, VueQrcode);
var pc = document.getElementById('pc');
/* eslint-disable no-new */
if (isMobile) {
    pc.className = 'is-mobile';
    var MOBILE = new Vue({
        router: routerMobile,
        store: store,
        render: function (h) { return h(Mobile); },
        mounted: function () {
            document.dispatchEvent(new Event('render-event'));
        }
    }).$mount('mobile');
}
else {
    var PC = new Vue({
        router: routerPC,
        store: store,
        render: function (h) { return h(Pc); },
        mounted: function () {
            document.dispatchEvent(new Event('render-event'));
            // 配合媒体查询与预渲染，当是pc的时候不会因为分辨率小而不显示
            setTimeout(function () {
                pc.className = '';
            }, 5000);
        },
    }).$mount('pc');
}
(function sendAjax() {
    //构造表单数据
    var xhr = new XMLHttpRequest();
    //设置xhr请求的超时时间
    xhr.timeout = 3000;
    //设置响应返回的数据格式
    xhr.responseType = "text";
    //创建一个 post 请求，采用异步
    xhr.open('GET', 'https://api.markicam.com/wms/getVersionInfo', true);
    //注册相关事件回调处理函数
    xhr.onload = function (e) {
        if (this.status == 200 || this.status == 304) {
            if (this.responseText)
                try {
                    var res = JSON.parse(this.responseText);
                    if (res.code == 1) {
                        window.apkUrl = res.data.apk_url;
                    }
                    else {
                    }
                }
                catch (e) {
                    console.log(e);
                }
        }
    };
    xhr.ontimeout = function (e) {
        console.log("Timeout: ", e);
    };
    xhr.onerror = function (e) {
        console.log("Error: ", e);
    };
    xhr.upload.onprogress = function (e) {
    };
    //发送数据
    xhr.send();
})();
function copyTxt(text) {
    text = window.location.href;
    if (typeof document.execCommand !== "function") {
        return;
    }
    var dom = document.createElement("textarea");
    dom.value = text;
    dom.setAttribute('style', 'display: block;width: 1px;height: 1px;');
    document.body.appendChild(dom);
    dom.select();
    var result = document.execCommand('copy');
    document.body.removeChild(dom);
    var range = document.createRange();
    var div = document.createElement('div');
    div.innerHTML = text;
    div.setAttribute('style', 'height: 1px;fontSize: 1px;overflow: hidden;');
    document.body.appendChild(div);
    range.selectNode(div);
    var selection = window.getSelection();
    if (selection.rangeCount > 0) {
        selection.removeAllRanges();
    }
    selection.addRange(range);
    document.execCommand('copy');
}
window.copyTxt = copyTxt;
